import React from "react";

import { Icon } from '@types';

const EmailIcon: Icon = ({ fill = "white" }) => (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.66,3.38C11.45,4.22,10.22,5.06,9,5.92,8.5,6.27,7.64,7,7,7H7C6.36,7,5.5,6.27,5,5.92c-1.21-.86-2.44-1.7-3.65-2.54A3.07,3.07,0,0,1,0,1.41,1.26,1.26,0,0,1,1.25,0h11.5A1.26,1.26,0,0,1,14,1.24,2.83,2.83,0,0,1,12.66,3.38ZM14,9.74A1.26,1.26,0,0,1,12.75,11H1.25A1.26,1.26,0,0,1,0,9.74V3.53a4,4,0,0,0,.79.68C2.09,5.1,3.4,6,4.67,6.91A4.33,4.33,0,0,0,7,8H7A4.33,4.33,0,0,0,9.33,6.91C10.6,6,11.91,5.1,13.22,4.21A4.18,4.18,0,0,0,14,3.53Z"
      fill={fill}
    />
  </svg>
);

export default EmailIcon;
