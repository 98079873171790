import React from "react";

import { Icon } from '@types';

const PhoneIcon: Icon = ({ fill = "white" }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 13.222656 9.722656 C 12.253906 9.722656 11.316406 9.566406 10.445312 9.277344 C 10.175781 9.191406 9.871094 9.253906 9.65625 9.46875 L 7.945312 11.183594 C 5.738281 10.066406 3.941406 8.265625 2.820312 6.0625 L 4.53125 4.34375 C 4.746094 4.128906 4.808594 3.824219 4.722656 3.554688 C 4.433594 2.683594 4.277344 1.746094 4.277344 0.777344 C 4.277344 0.347656 3.933594 0 3.5 0 L 0.777344 0 C 0.351562 0 0 0.347656 0 0.777344 C 0 8.082031 5.917969 14 13.222656 14 C 13.652344 14 14 13.652344 14 13.222656 L 14 10.5 C 14 10.066406 13.652344 9.722656 13.222656 9.722656 Z M 13.222656 9.722656"
      fill={fill}
    />
  </svg>
);

export default PhoneIcon;
