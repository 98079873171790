import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import { Icon } from '@types';

const Logo: Icon = ({ fill = "white" }) => {
  return (
    <LogoContainer>
      <svg
        width="200"
        height="30"
        viewBox="0 0 200 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Desktop"
      >
        <g clipPath="url(#clip0)">

          <path
            fill="#73737d"
            d="M51.05,21a5.39,5.39,0,1,1-2.88-9.93,5.47,5.47,0,0,1,2.88.8v1.41a4.43,4.43,0,0,0-2.88-1,4.19,4.19,0,0,0,0,8.37,4.58,4.58,0,0,0,2.88-1Z"
          />
          <path
            fill="#73737d"
            d="M58.68,11.08a5.29,5.29,0,0,1,5.38,5.38,5.38,5.38,0,1,1-10.75,0A5.29,5.29,0,0,1,58.68,11.08Zm0,9.57a4.19,4.19,0,1,0-4.11-4.19A4.11,4.11,0,0,0,58.68,20.65Z"
          />
          <path
            fill="#73737d"
            d="M68.44,13.1a3.42,3.42,0,0,1,3.29-2,3.89,3.89,0,0,1,4,4.21v6.35h-1.2V15.51a3,3,0,0,0-3.06-3.23,2.91,2.91,0,0,0-3,3.1v6.26H67.22V11.3h1.22Z"
          />
          <path
            fill="#73737d"
            d="M80.72,13.1a3.42,3.42,0,0,1,3.29-2,3.89,3.89,0,0,1,4,4.21v6.35H86.8V15.51a3,3,0,0,0-3-3.23,2.91,2.91,0,0,0-3,3.1v6.26H79.49V11.3h1.23Z"
          />
          <path
            fill="#73737d"
            d="M96.44,11.08a5.28,5.28,0,0,1,5.37,5.38,5.38,5.38,0,1,1-10.75,0A5.29,5.29,0,0,1,96.44,11.08Zm0,9.57a4.1,4.1,0,0,0,4.12-4.19,4.12,4.12,0,1,0-8.23,0A4.1,4.1,0,0,0,96.44,20.65Z"
          />
          <path
            fill="#73737d"
            d="M106.2,12.84A2.87,2.87,0,0,1,109,11.08a4,4,0,0,1,.92.11V12.5a3.18,3.18,0,0,0-1.11-.22c-1.77,0-2.54,1.27-2.58,3.1v6.26H105V11.3h1.23Z"
          />
          
          <path
            fill={fill}
            d="M113.21,11.17l2.4,8.23,2.54-6.92-.52-1.31h1.83l3.31,8.23,2.41-8.23h1.87l-3.22,10.47h-1.9l-2.9-7.1-2.58,7.1h-1.91l-3.21-10.47Z"
          />
          <path
            fill={fill}
            d="M132.36,12.82A3.37,3.37,0,0,1,135.5,11a3.85,3.85,0,0,1,3.94,4.15v6.54h-1.79V15.53A2.58,2.58,0,0,0,135,12.71a2.55,2.55,0,0,0-2.65,2.69v6.24h-1.78V6.8h1.78Z"
          />
          <path
            fill={fill}
            d="M144.79,6.69A1.16,1.16,0,0,1,146,7.88a1.18,1.18,0,0,1-2.36,0A1.16,1.16,0,0,1,144.79,6.69Zm.9,15h-1.78V11.17h1.78Z"
          />
          <path
            fill={fill}
            d="M151.11,21.64V12.71h-2V11.17h2V8.61l1.69-.45v3H155v1.54h-2.07v8.93Z"
          />
          <path
            fill={fill}
            d="M160.12,12.61A3.17,3.17,0,0,1,163,11a3.47,3.47,0,0,1,3.38,2.17A3.19,3.19,0,0,1,169.6,11a3.62,3.62,0,0,1,3.68,3.91v6.78h-1.79V15.29a2.31,2.31,0,0,0-2.38-2.58,2.33,2.33,0,0,0-2.41,2.58v6.35h-1.79V15.29a2.4,2.4,0,1,0-4.79-.15v6.5h-1.79V11.17h1.79Z"
          />
          <path
            fill={fill}
            d="M178.05,11.68a7,7,0,0,1,3-.73c2.36,0,4,1.21,4,4v6.67h-1.78V20.5a3.29,3.29,0,0,1-2.76,1.35,3.42,3.42,0,0,1-3.65-3.48,3.47,3.47,0,0,1,3.68-3.44,3.7,3.7,0,0,1,2.73,1.22V15a2.23,2.23,0,0,0-2.43-2.36,5.41,5.41,0,0,0-2.84.86Zm2.94,8.8a2.2,2.2,0,0,0,2.3-2.11,2.31,2.31,0,0,0-4.6,0A2.22,2.22,0,0,0,181,20.48Z"
          />
          <path
            fill={fill}
            d="M191.36,12.82A3.34,3.34,0,0,1,194.5,11a3.85,3.85,0,0,1,3.93,4.15v6.54h-1.78V15.53A2.59,2.59,0,0,0,194,12.71a2.54,2.54,0,0,0-2.64,2.69v6.24h-1.79V11.17h1.79Z"
          />

          <polygon
            points="29.98 19.38 31.44 21.7 44.03 0 41.23 0 29.98 19.38"
            fill={fill}
          />
          <polygon
            points="26.62 30 27.96 27.68 13.38 2.56 13.46 2.56 19.44 2.56 30.16 2.56 25.01 11.44 26.61 13.99 34.73 0 9.21 0 26.62 30"
            fill={fill}
          />
          <polygon
            points="17.19 30 18.83 27.79 2.7 0 -0.22 0 17.19 30"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="200" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Mobile"
      >
        <polygon
          points="16.49 14.64 17.78 16.86 25 4.43 22.42 4.43 16.49 14.64"
          fill={fill}
        />
        <polygon
          points="14.66 22.25 15.92 20.07 8.3 6.87 16.59 6.87 14.29 10.83 15.66 13.2 20.74 4.43 4.33 4.43 14.66 22.25"
          fill={fill}
        />
        <polygon
          points="9.84 21.38 11.16 19.11 2.65 4.43 0 4.43 9.84 21.38"
          fill={fill}
        />
      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
